import {
  useCachedCategories,
  useCachedPaymentMethods,
  useCachedMembers,
  useCachedReports,
  useWorkspaceStore,
} from '@easy-expense/data-firestore-client';
import {
  CategoryValue,
  Expense,
  PaymentMethodValue,
  VendorValue,
  WorkspaceMember,
} from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getUserDisplayName } from '@easy-expense/utils-shared';
import _ from 'lodash';
import React from 'react';

import { ExpenseWithData } from './ExpenseRow.component';

export const useMappedExpenseData = (): ExpenseWithData[] => {
  const expenses = Data.expenses.use();
  const vendors = Data.vendors.use();
  const categories = useCachedCategories();
  const paymentMethods = useCachedPaymentMethods();
  const members = useWorkspaceStore((s) => s.workspaceMembers);
  const reports = useCachedReports();

  return React.useMemo(() => {
    return expenses.map((e) => {
      const vendorValue = vendors?.find((v) => v.key === e.vendor)?.value as VendorValue;
      const categoryValue = categories?.find((c) => c.key === e.category)?.value as CategoryValue;
      const paymentMethodValue = paymentMethods?.find((pm) => pm.key === e.paymentMethod)
        ?.value as PaymentMethodValue;
      const member = members[e.createdBy] as WorkspaceMember;
      const creatorDisplayName = getUserDisplayName(member);
      const reportsToAdd = Object.values(reports)?.filter((r) => r.expenses?.includes(e.key));

      return {
        ...e,
        vendorValue,
        categoryValue,
        paymentMethodValue,
        creatorDisplayName,
        reports: reportsToAdd,
      };
    });
  }, [expenses, vendors, categories, paymentMethods, reports, members]);
};

export const applyFilters = (
  expenses: ExpenseWithData[],
  filter: (e: Expense) => boolean,
  filterUser?: string,
): ExpenseWithData[] => {
  return expenses.filter((e) => {
    if (!filter(e)) {
      return false;
    }
    if (filterUser && e.createdBy !== filterUser) {
      return false;
    }
    return true;
  });
};

export const useSearchExpenseList = (
  expenses: ExpenseWithData[],
  search?: string,
): ExpenseWithData[] => {
  const terms = [
    'vendorValue.name',
    'categoryValue.name',
    'desc',
    'total',
    'paymentMethodValue.name',
  ];

  return React.useMemo(() => {
    if (search) {
      return expenses.filter((listEntity) => {
        const entityValues = terms.map((term) => _.get(listEntity, term));

        const searchValues = entityValues.reduce((acc, entityValue) => {
          if (entityValue) {
            entityValue = typeof entityValue === 'string' ? entityValue : entityValue.toString();
            acc.push(entityValue.toLowerCase());
          }
          return acc;
        }, []);

        const expenseValuesString = searchValues.join(' ');
        return expenseValuesString.includes(search.toLowerCase());
      });
    } else {
      return expenses;
    }
  }, [expenses, search]);
};
