import {
  useIsAdminOrManagerRole,
  useCachedCategories,
  useCachedPaymentMethods,
  useReviewTransactionAccountSummary,
} from '@easy-expense/data-firestore-client';
import { Category } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation } from '@easy-expense/intl-client';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { ExportFilters } from './types';
import { useMembersAutoComplete } from '../Report/ShareReportButton';
import { SearchDropdown } from '../Shared/Dropdown/SearchDropDown.component';

export const ExportFilter: React.FC<{
  exportFilters: ExportFilters;
  setExportFilters: (filters: ExportFilters) => void;
}> = ({ exportFilters, setExportFilters }) => {
  const membersValues = useMembersAutoComplete();

  const isAdminOrManager = useIsAdminOrManagerRole();

  if (!isAdminOrManager) {
    return null;
  }

  return (
    <Layout.Column>
      <Spacer.Vertical size={16} />
      <Layout.Row align>
        <OpenSans.Primary size={22} weight="bold-700">
          {getTranslation('Filters')}
        </OpenSans.Primary>
      </Layout.Row>
      <Spacer.Vertical size={16} />

      <Layout.Row align>
        <SearchDropdown
          header={getTranslation('Created By')}
          placeholder={getTranslation('All Users')}
          selectedKey={exportFilters.createdByKey}
          autoCompleteValues={[
            { key: undefined, value: { name: 'All Users', iconicIcon: 'people-circle-outline' } },
            ...membersValues,
          ]}
          onSelect={(c) => {
            setExportFilters({ ...exportFilters, createdByKey: c?.key });
          }}
          error={false}
          iconicIcon="people-circle-outline"
        />
      </Layout.Row>

      <Spacer.Vertical size={16} />
    </Layout.Column>
  );
};
